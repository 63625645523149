$white: #fff;

/*
dark, middle, light
 */
$darkPrimary: #34334b;
$darkAccent: #605e7c;
$middle: #9391af;
$lightAccent: #c9cbd8;
$lightAccentR: 201;
$lightAccentG: 203;
$lightAccentB: 216;
$lightPrimary: #f4f4f8;

/*
primary
 */
$matPrimary50: #f4e8ff;
$matPrimary100: #e9d2ff;
$matPrimary200: #d3a7fe;
$matPrimary300: #c081ff;
$matPrimary400: #a851ff;
$matPrimary500: #9327fe;
$matPrimary600: #7327fe;
$matPrimary700: #6427fe;
$matPrimary800: #6245fc;
$matPrimary900: #3711ff;
$matPrimary: (
  50: $matPrimary50,
  100: $matPrimary100,
  200: $matPrimary200,
  300: $matPrimary300,
  400: $matPrimary400,
  500: $matPrimary500,
  600: $matPrimary600,
  700: $matPrimary700,
  800: $matPrimary800,
  900: $matPrimary900,
  A100: $matPrimary100,
  A200: $matPrimary200,
  A400: $matPrimary400,
  A700: $matPrimary700,
  contrast: (
    50: $darkPrimary,
    100: $darkPrimary,
    200: $darkPrimary,
    300: $darkPrimary,
    400: $darkPrimary,
    500: $lightPrimary,
    600: $lightPrimary,
    700: $lightPrimary,
    800: $lightPrimary,
    900: $lightPrimary,
    A100: $darkPrimary,
    A200: $lightPrimary,
    A400: $lightPrimary,
    A700: $lightPrimary,
  )
);

/*
accent
 */
$matAccent50: #c9ebff;
$matAccent100: #a5deff;
$matAccent200: #7ccfff;
$matAccent300: #53c0ff;
$matAccent400: #30b4ff;
$matAccent500: #0da7ff;
$matAccent600: #0097ed;
$matAccent700: #0086d3;
$matAccent800: #0078bd;
$matAccent900: #0068a4;
$matAccent: (
  50: $matAccent50,
  100: $matAccent100,
  200: $matAccent200,
  300: $matAccent300,
  400: $matAccent400,
  500: $matAccent500,
  600: $matAccent600,
  700: $matAccent700,
  800: $matAccent800,
  900: $matAccent900,
  A100: $matAccent100,
  A200: $matAccent200,
  A400: $matAccent400,
  A700: $matAccent700,
  contrast: (
    50: $darkPrimary,
    100: $darkPrimary,
    200: $darkPrimary,
    300: $darkPrimary,
    400: $darkPrimary,
    500: $lightPrimary,
    600: $lightPrimary,
    700: $lightPrimary,
    800: $lightPrimary,
    900: $lightPrimary,
    A100: $darkPrimary,
    A200: $lightPrimary,
    A400: $lightPrimary,
    A700: $lightPrimary,
  )
);

/*
warn
 */
$matWarn50: #ffcfd7;
$matWarn100: #ffa2b1;
$matWarn200: #fe7f93;
$matWarn300: #fa5873;
$matWarn400: #f63756;
$matWarn500: #df2543;
$matWarn600: #cc112f;
$matWarn700: #b70421;
$matWarn800: #a2011b;
$matWarn900: #8c0017;
$matWarn: (
  50: $matWarn50,
  100: $matWarn100,
  200: $matWarn200,
  300: $matWarn300,
  400: $matWarn400,
  500: $matWarn500,
  600: $matWarn600,
  700: $matWarn700,
  800: $matWarn800,
  900: $matWarn900,
  A100: $matWarn100,
  A200: $matWarn200,
  A400: $matWarn400,
  A700: $matWarn700,
  contrast: (
    50: $darkPrimary,
    100: $darkPrimary,
    200: $darkPrimary,
    300: $darkPrimary,
    400: $darkPrimary,
    500: $lightPrimary,
    600: $lightPrimary,
    700: $lightPrimary,
    800: $lightPrimary,
    900: $lightPrimary,
    A100: $darkPrimary,
    A200: $lightPrimary,
    A400: $lightPrimary,
    A700: $lightPrimary,
  )
);

/*
success
 */
$matSuccess50: #beffe1;
$matSuccess100: #71f5b9;
$matSuccess200: #3ef09e;
$matSuccess300: #23e089;
$matSuccess400: #0ccc74;
$matSuccess500: #0cba6a;
$matSuccess600: #03a45a;
$matSuccess700: #008d4c;
$matSuccess800: #007b42;
$matSuccess900: #006738;
$matSuccess: (
  50: $matSuccess50,
  100: $matSuccess100,
  200: $matSuccess200,
  300: $matSuccess300,
  400: $matSuccess400,
  500: $matSuccess500,
  600: $matSuccess600,
  700: $matSuccess700,
  800: $matSuccess800,
  900: $matSuccess900,
  A100: $matSuccess100,
  A200: $matSuccess200,
  A400: $matSuccess400,
  A700: $matSuccess700,
  contrast: (
    50: $darkPrimary,
    100: $darkPrimary,
    200: $darkPrimary,
    300: $darkPrimary,
    400: $darkPrimary,
    500: $lightPrimary,
    600: $lightPrimary,
    700: $lightPrimary,
    800: $lightPrimary,
    900: $lightPrimary,
    A100: $darkPrimary,
    A200: $lightPrimary,
    A400: $lightPrimary,
    A700: $lightPrimary,
  )
);

/*
extra
 */
$matExtra50: #f9e2cc;
$matExtra100: #f2c59a;
$matExtra200: #f1b57a;
$matExtra300: #ed9f54;
$matExtra400: #e7913d;
$matExtra500: #df8125;
$matExtra600: #cc6d11;
$matExtra700: #b85e06;
$matExtra800: #9f5002;
$matExtra900: #864200;
$matExtra: (
  50: $matExtra50,
  100: $matExtra100,
  200: $matExtra200,
  300: $matExtra300,
  400: $matExtra400,
  500: $matExtra500,
  600: $matExtra600,
  700: $matExtra700,
  800: $matExtra800,
  900: $matExtra900,
  A100: $matExtra100,
  A200: $matExtra200,
  A400: $matExtra400,
  A700: $matExtra700,
  contrast: (
    50: $darkPrimary,
    100: $darkPrimary,
    200: $darkPrimary,
    300: $darkPrimary,
    400: $darkPrimary,
    500: $lightPrimary,
    600: $lightPrimary,
    700: $lightPrimary,
    800: $lightPrimary,
    900: $lightPrimary,
    A100: $darkPrimary,
    A200: $lightPrimary,
    A400: $lightPrimary,
    A700: $lightPrimary,
  )
);

/*
social
 */
$facebook: #3b5998;
$twitter: #00aced;
$youTube: #c4302b;
$instagram: #e1306c;
$linkedIn: #0077b5;
