@import '~@angular/material/theming';
@import "~bootstrap";
//@import '../node_modules/@angular/material/theming';
@import "styles-var";

@include mat-core();
$appPrimary: mat-palette($matPrimary);
$appAccent: mat-palette($matAccent);
$appWarn: mat-palette($matWarn);
$appTheme: mat-light-theme($appPrimary, $appAccent, $appWarn);
@include angular-material-theme($appTheme);

$general-typography: mat-typography-config(
  $font-family: '"Quicksand", "Helvetica Neue", sans-serif'
);
@include mat-core($general-typography);

/*
main
 */
html {
  //position: fixed;
}

body {
  font-family: 'Quicksand', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  //position: fixed;
  width: 100%;
  height: 100%;
  background-color: $lightPrimary;
  color: $darkPrimary;
}

body .aside-subnav {
  width: 180px;
  position: fixed;
  left: 180px;
  top: 0;
  bottom: 0;
  transition: all .2s ease-in-out;

  .search {
    .mat-form-field {
      width: 100%;
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      .mat-form-field-wrapper {
        margin: 15px 20px 0 20px;
      }
    }

    .mat-slide-toggle {
      display: none;
    }
  }

  .mat-nav-list {
    position: absolute;
    overflow: auto;
    top: 102px;
    bottom: 20px;
    left: 0;
    right: 0;

    &.mat-nav-list--button {
      bottom: 68px;
    }

    .mat-list-item {
      position: relative;
      transition: all .2s ease-in-out;

      &.mat-list-item--favorite {
        background-color: $white;
        &:hover {
          background-color: $white;
        }
      }

      .mat-list-item-content {
        padding: 15px;

        .mat-line {
          font-weight: bold;

          small {
            color: $middle;
          }
        }

        .mat-list-item-link {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .favorite {
          position: absolute;
          top: 5px;
          right: 5px;
          color: $middle;
          .material-icons {
            font-size: 18px;
            transition: all .2s ease-in-out;
          }
        }

        .info {
          font-weight: normal;
          white-space: nowrap;
          margin-top: 5px;
          color: $middle;

          .mat-icon {
            font-size: 14px;
            height: 14px;
            width: 14px;
            margin-right: 5px;
          }

          small {
            position: relative;
            top: -2px;
            margin-right: 10px;
          }
        }

        .chart {
          display: none;
        }
      }

      &.register {
        small {
          padding: 0 20px;
        }
      }

      &:hover {
        background-color: transparent;
        .favorite {
          .material-icons {
            transform: scale(1.2);
          }
        }
      }

      &.active {
        background-color: transparent;
        color: $white;

        .mat-list-item-content .mat-list-text .mat-line small {
          color: $white;
        }

        .info {
          color: $white;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          background: linear-gradient(45deg, $matPrimary500 0%, $matPrimary800 100%);
        }
      }

      &.disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  &.aside-subnav--full-page {
    right: 180px;
    width: auto;

    .mat-slide-toggle {
      display: block;
    }

    .mat-nav-list {
      padding-left: 20px;
      padding-right: 20px;
    }

    .mat-list-item {
      transition: all .2s ease-in-out;
      //box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04);
      &:hover {
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
        background-color: $white;
      }
    }
  }

  &.aside-subnav--full-projects {
    right: 180px;
    width: auto;

    .search {
      display: flex;
      align-items: center;

      .mat-slide-toggle {
        display: block;
      }
    }

    .mat-nav-list {
      top: 88px;
    }

    .mat-list-item {
      width: 200px;
      height: 145px;
      float: left;
      background-color: $white;
      margin: 15px;
      position: relative;
      transition: all .2s ease-in-out;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04);

      .mat-list-item-content {
        align-items: flex-start;

        .info {
          position: absolute;
          bottom: 15px;
          left: 15px;
          right: 15px;

          .info-estimation {
            margin-left: 20px;
            display: block;
            position: relative;

            .mat-icon {
              position: absolute;
              left: -20px;
            }

            small {
              position: relative;
              top: -5px;
            }

            .chart {
              display: block;
              position: absolute;
              background-color: $lightAccent;
              left: 0;
              bottom: 0;
              right: 0;
              height: 3px;
              border-radius: 2px;

              .chart-line {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 3px;
                border-radius: 2px;

                &.chart-line--danger {
                  background-color: $matWarn500;
                }

                &.chart-line--warn {
                  background-color: $matExtra500;
                }

                &.chart-line--success {
                  background-color: $matSuccess500;
                }
              }
            }
          }

          .info-deadline {
            margin-top: 10px;
            display: block;
          }
        }
      }

      &:hover {
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
        background-color: $white;
      }
    }
  }

  .container-btm {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

body .mat-drawer-content {
  z-index: auto;
}

main {
  //margin-left: 180px;
  //margin-right: 180px;
}

.page-header {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  h1 {
    font-weight: bold;
    padding: 0;
    height: 100%;
    line-height: 80px;
    font-size: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .page-header-settings {
    line-height: 58px;

    .element {
      display: inline-block;
      margin-left: 20px;
      font-weight: bold;
    }
  }

  .mat-tab-nav-bar {
    border-bottom: none;

    .mat-tab-links {
      justify-content: flex-end;
    }

    .mat-tab-link {
      height: 60px;
      color: $darkPrimary;
      opacity: 1;
      font-weight: bold;
      min-width: 75px;
      padding: 0 20px;
    }

    .mat-tab-link:not(.mat-tab-disabled):focus {
      background: transparent;
    }
  }
}

.page-section {
  margin-top: 22px;
  margin-bottom: 20px;

  .page-section-header {
    .mat-form-field {
      width: auto;
      margin-bottom: 0;
    }
  }
}

.container {
  max-width: 100%;
}

/*
headers
 */
h1 {
  font-weight: normal;
  margin: 0;
}

/*
links
 */
.link {
  color: $matPrimary500;
  text-decoration: none;
  position: relative;
  transition: all .2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: $matPrimary800;
  }

  &::after {
    transition: all 0.2s ease-in-out;
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 1px;
    background-color: $matPrimary500;
  }

  &:hover::after {
    left: 0;
    width: 100%;
    background-color: $matPrimary800;
  }
}

.link-close {
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
}

/*
code
 */
.code {
  display: block;
}

/*
alert
 */
.alert {
  padding: 10px 15px;
  border-radius: 20px;
  color: #fff;

  &.success {
    background: linear-gradient(45deg, $matSuccess600 0%, $matSuccess500 100%);
  }

  &.warn {
    background: linear-gradient(45deg, $matWarn600 0%, $matWarn500 100%);
  }

  &.info {
    background: linear-gradient(45deg, $matAccent600 0%, $matAccent500 100%);
  }
}

/*
mat container
 */
.mat-drawer-container {
  background-color: #f4f4f8;
  color: $darkPrimary;
}

/*
mat tabs
 */
body .mat-tab-group,
body .mat-tab-nav-bar {
  .mat-ink-bar {
    background: linear-gradient(45deg, $matPrimary500 0%, $matPrimary800 100%);
    height: 4px;
  }

  &.mat-primary {
    .mat-tab-label:not(.mat-tab-disabled):focus {
      background: transparent;
    }
  }
}

/*
mat buttons
 */
// basic buttons
body .mat-button {
  min-width: 0;
  padding: 0;
  line-height: normal;
  transition: all .2s ease-in-out;
  color: $darkAccent;

  &.mat-primary {
    color: $matPrimary500;
  }

  &:hover .mat-button-focus-overlay {
    background-color: $white;
  }
}

// raised buttons
body .mat-raised-button,
body .mat-raised-button:not([class*=mat-elevation-z]) {
  transition: all .2s ease-in-out;
  border-radius: 20px;
  box-shadow: none;
  padding: 0 25px;
  line-height: 40px;
  font-weight: bold;
  color: $white;

  &.mat-primary[disabled] {
    background: linear-gradient(45deg, $matPrimary800 0%, $matPrimary500 100%);
    opacity: .5;
    color: #fff;
    cursor: not-allowed;

    &:hover {
      box-shadow: inset 0 0 0 2px $matPrimary500, 0 0 0 3px $matPrimary200;
    }
  }

  &.mat-success {
    background: linear-gradient(45deg, $matSuccess800 0%, $matSuccess500 100%);

    &:hover {
      box-shadow: inset 0 0 0 2px $matSuccess500, 0 0 0 3px $matSuccess200;
    }
  }

  &.mat-warn {
    background: linear-gradient(45deg, $matWarn800 0%, $matWarn500 100%);

    &:hover {
      box-shadow: inset 0 0 0 2px $matWarn500, 0 0 0 3px $matWarn200;
    }
  }

}

// stroke buttons
body .mat-stroked-button,
body .mat-stroked-button:not([class*=mat-elevation-z]) {
  transition: all .2s ease-in-out;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px $middle;
  padding: 0 25px;
  font-weight: bold;
  color: $middle;
  border: 0;

  &.mat-primary {
    box-shadow: inset 0 0 0 2px $matPrimary500;
    color: $matPrimary500;

    &:hover {
      box-shadow: inset 0 0 0 2px $matPrimary500, 0 0 0 3px $matPrimary200;
    }
  }

  &.mat-success {
    box-shadow: inset 0 0 0 2px $matSuccess500;
    color: $matSuccess500;

    &:hover {
      box-shadow: inset 0 0 0 2px $matSuccess500, 0 0 0 3px $matSuccess200;
    }
  }

  &.mat-warn {
    box-shadow: inset 0 0 0 2px $matWarn500;
    color: $matWarn500;

    &:hover {
      box-shadow: inset 0 0 0 2px $matWarn500, 0 0 0 3px $matWarn200;
    }
  }

  &.mat-white {
    box-shadow: inset 0 0 0 2px $white;
    color: $white;


    &:hover {
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 1), 0 0 0 3px rgba(255, 255, 255, .4);
    }
  }

  &:hover {
    box-shadow: inset 0 0 0 2px $middle, 0 0 0 3px $lightAccent;

    .mat-button-focus-overlay {
      opacity: 0;
    }
  }

  &[disabled] {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

}

// flat buttons
body .mat-flat-button {
  background-color: $lightAccent;
  border-radius: 20px;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow: 0 0 0 3px $lightPrimary;
  }
}

// chips
body .mat-chip {
  &.mat-standard-chip {
    background-color: $lightAccent;
  }
}

/*
mat menu panel
 */
body .mat-menu-panel {
  box-shadow: 0 33px 33px 0 rgba(0, 0, 0, 0.1);

  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mat-menu-item {
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: $darkPrimary;
  }
}

/*
mat forms elements
 */
body .mat-form-field {
  //margin-bottom: 20px;
  width: 100%;
  &.mat-form-field--readonly {
    .mat-form-field-underline {
      display: none;
    }
  }

  .mat-autosize {
    line-height: 21px;
    overflow: hidden;
  }
}

body .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
}

body .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: $lightPrimary;
}

body .mat-autocomplete-panel.mat-autocomplete-visible {
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;
}

body .mat-datepicker-content {
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;

  .mat-calendar-header {
    padding-left: 25px;
  }
}

body .mat-radio-label {
  white-space: normal;
}

body .mat-pseudo-checkbox-checked::after {
  width: 10px;
  height: 5px;
}

/*
mat snackbar
 */
.cdk-overlay-container {
  z-index: 1000000;

  .mat-snack-bar-container {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.3) !important;
    margin: 0;

    &.success {
      background: linear-gradient(45deg, $matSuccess600 0%, $matSuccess500 100%);
    }

    &.warn {
      background: linear-gradient(45deg, $matWarn600 0%, $matWarn500 100%);
    }

    &.info {
      background: linear-gradient(45deg, $matAccent600 0%, $matAccent500 100%);
    }
  }
}

/*
mat list
 */
body .mat-nav-list,
body .mat-selection-list {
  padding-top: 0;
  outline: none;
}

body .mat-list .mat-list-item,
body .mat-nav-list .mat-list-item,
body .mat-selection-list .mat-list-item {
  color: $darkPrimary;
  font-size: 14px;
  font-weight: bold;
  outline: none;

  .mat-list-item-content {
    //padding: 0 20px;
    .mat-list-text {
      font-weight: bold;
    }
  }

  &.active {
    .mat-badge {
      .mat-badge-content {
        background-color: $white;
        color: $matPrimary500;
      }
    }
  }
}

body .mat-list-item-content {
  .mat-pseudo-checkbox-checked::after {
    box-sizing: content-box;
  }
}

body .mat-drawer-container.sidenav-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .mat-drawer-inner-container {
    overflow-x:hidden;
  }
}

body .mat-list-base .mat-list-item .mat-list-item-content,
body .mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0;
}

/*
mat badge
 */
body .mat-badge-content {
  overflow: visible;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: pulse 1.2s ease infinite;
    border-radius: 50%;
    background-color: $matPrimary500;
    z-index: -1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.4);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

/*
mat card
 */
body .mat-card,
body .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04);
  border-radius: 0;
  padding: 15px;

  &:hover {
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
  }
}

body .mat-card--dashboard,
body .mat-card--dashboard:not([class*=mat-elevation-z]),
body .mat-card--estimations,
body .mat-card--estimations:not([class*=mat-elevation-z]),
body .mat-card--calendar {
  color: $darkPrimary;
  border-radius: 0;
  margin-bottom: 50px;

  .mat-card-header {
    .mat-card-header-text {
      margin: 0;
    }

    .mat-card-title {
      font-size: 24px;
      font-weight: bold;
    }

    .mat-card-subtitle {
      font-weight: bold;
      color: $middle;
    }
  }
}

body .mat-card--user,
body .mat-card--user:not([class*=mat-elevation-z]) {
  color: $darkPrimary;
  margin-top: 20px;
  left: -20px;
  margin-left: 20px;
  float: left;
  width: 220px;

  .mat-card-link {
    text-decoration: none;

    .mat-card-title {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 130px;
      font-weight: bold;
      color: $darkPrimary;

      small {
        color: $middle;
        font-size: 12px;
      }
    }

    .mat-card-avatar {
      background-position: center;
      background-size: cover;
      height: 40px;
      width: 40px;
      background-color: $lightAccent;
      text-align: center;
      font-weight: bold;
      line-height: 40px;
      text-transform: uppercase;
      text-decoration: none;
      color: $darkPrimary;
      transition: all .2s ease-in-out;
    }

    &:hover {
      .mat-card-avatar {
        box-shadow: 0 0 0 3px rgba($lightAccentR, $lightAccentG, $lightAccentB, .5);
      }
    }
  }

  .mat-card-actions {
    margin: 0;
    padding: 0;
  }
}

/*
mat extension panel
 */
body .mat-expansion-panel:not([class*=mat-elevation-z]),
body .mat-expansion-panel:not(.mat-expanded) {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04);

  &:hover {
    background-color: $white;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-header:not([aria-disabled=true]) {
    font-size: 14px;
    padding: 0;

    &:hover {
      background-color: $white;
    }

    .mat-content {
      align-items: center;
    }

    .mat-expansion-panel-header-description {
      color: $middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }

  .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
  }

  .expansion-panel__image {
    img {
      max-width: 600px;
    }

    margin-top: 20px;
  }
}

/*
mat slide toggle
 */
body .mat-slide-toggle {
  .mat-slide-toggle-bar {
    background-color: $lightAccent;
  }
}

/*
mat button toggle group
 */
body .mat-button-toggle-group {
  box-shadow: none;
  border: 1px solid $lightAccent;

  .mat-button-toggle {
    color: $middle;
  }

  .mat-button-toggle-checked {
    background-color: $lightAccent;
    color: $darkPrimary;
  }

  .mat-button-toggle-label-content {
    line-height: 30px;
  }
}

/*
mat tooltip
 */
body .mat-tooltip {
  font-size: 12px !important;
}

/*
mat dialog
 */
body .mat-dialog-container {
  border-radius: 0;

  .mat-dialog-content {
    max-height: 100vh;
    margin: -24px;
    position: relative;
  }
}

/*
loading
 */
.spinner {
  width: 70px;
  text-align: center;
  margin: 25px auto;

  &.spinner--margin0 {
    margin: 0;
  }

  div {
    width: 18px;
    height: 18px;
    background-color: $darkPrimary;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: $matPrimary500;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: $matAccent500;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

/*
quill-editor
 */
quill-editor {
  .ql-snow {
    &.ql-toolbar {
      background-color: #ffffff;
      z-index: 1;
      border: 0;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04);
      transition: all .2s ease-in-out;

      &:hover {
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
      }

      .ql-picker.ql-expanded {
        .ql-picker-label:focus {
          outline: none;
        }
      }

      button:focus {
        outline: none;
      }

      button:hover,
      .ql-picker-label.ql-active,
      button.ql-active {
        color: $matAccent500;
      }
    }

    &.ql-container {
      border: 0;
    }

    .ql-tooltip {
      z-index: 1;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border: 0;
      color: $darkPrimary;

      input[type=text] {
        outline: none;
        border-color: $lightAccent;
        border-radius: 13px;
      }

      a {
        color: $matPrimary500;
      }
    }
  }

  .ql-editor {
    padding: 60px 0 20px;
    font-size: 14px;
    font-family: "Quicksand", "Helvetica Neue", sans-serif;

    h1 {
      font-weight: bold;
    }
  }
}

.ql-editor-content {
  h1 {
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 2em;
  }

  h2 {
    font-weight: bold;
    margin: 0;
    font-size: 1.5em;
  }

  h3 {
    font-weight: bold;
    margin: 0;
    font-size: 1.17em;
  }

  h4 {
    font-weight: bold;
    margin: 0;
    font-size: 1em;
  }

  h5 {
    font-weight: bold;
    margin: 0;
    font-size: .83em;
  }

  h6 {
    font-weight: bold;
    margin: 0;
    font-size: .67em;
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

/*
drop files container
 */
.drop-container {
  border: 1px dashed $lightAccent;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  position: relative;
  text-align: center;
  transition: all .2s ease-in-out;
  margin: 0 3px 20px;

  &:hover,
  &.is-drop-over {
    box-shadow: 0 0 0 3px rgba($lightAccentR, $lightAccentG, $lightAccentB, .5);
  }

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

/*
row, col
 */
.col-header {
  font-weight: bold;
  margin-bottom: 20px;
}

/*
help classes
 */
.help-width-100p {
  width: 100%;
}

.help-margin-bottom-10px {
  margin-bottom: 10px;
}

.help-margin-left-10px {
  margin-left: 10px;
}

.help-margin-bottom-20px {
  margin-bottom: 20px;
}

.help-margin-bottom-30px {
  margin-bottom: 30px;
}

.help-margin-left-180px {
  margin-left: 180px;
}

.help-margin-left-360px {
  margin-left: 360px;
}

.help-text-align-center {
  text-align: center;
}

.help-text-align-left {
  text-align: left;
}

.help-text-align-right {
  text-align: right;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-tab-link-container {
  align-items: flex-start;
}
